var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ef84fe166b3abc570dc498e0d84005876d4503e5"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import config from "./config.js";

const IN_DEV = config.ENVIRONMENT !== "production";

Sentry.init({
  dsn: config.SENTRY_DSN,
  debug: IN_DEV,
  tracesSampleRate: 1.0,
  sampleRate: 1.0,
});
